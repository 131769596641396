import { Box, Stack } from '@mui/system';
import { Button, CircularProgress, Container, TextField, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';

interface State {
  status: "loading" | "success" | "error",
  error?: Error,
  response?: {
    id: string,
    message: string
  },
}

const App = () => {
  const [amount, setAmount] = useState(0);
  const [state, setState] = useState<State | undefined>();
  
  const handlePay = async () => {
    setState({ status: "loading" });
    const request = new PaymentRequest([{
      supportedMethods: 'https://pay.qps.io/pay.json'
    }], {
      total: {
        label: 'total',
        amount: { value: amount.toString(), currency: 'USD' }
      }
    });
    if (!await request.canMakePayment()) {
      setState({ status: "error", error: new Error("request.canMakePayment() is false") });
      return;
    }
    try {
      const response = await request.show();
      response.complete("success");
      setState({ status: "success", response: {
        id: response.requestId,
        message: response.details.message
      } });
    } catch (e) {
      if (e instanceof Error) {
        setState({ status: "error", error: e });
      } else {
        throw e
      }
    }
  }

  return (
    <Container sx={{ py: 3 }}>
      <Stack gap={3}>
        <Typography component="h1" variant="h5">Web to Android Payment</Typography>
        <TextField label="Amount" value={amount} type="number" onChange={e => setAmount(isNaN(Number(e.currentTarget.value)) ? 0 : Number(e.currentTarget.value))} />
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {window.PaymentRequest ? (
            <>
              {state?.status === "loading" ? <CircularProgress sx={{ mr: 2 }} size={20} /> : undefined}
              <Button onClick={handlePay}>Pay</Button>
            </>
          ) : (
            <Tooltip title="Device not supported">
              <span>
                <Button disabled>Pay</Button>
              </span>
            </Tooltip>
          )}
        </Box>
        {state?.status === "success" && state.response ? (
          <Stack>
            <Typography variant="h6">Response: Success</Typography>
            <Typography>{`Id: ${state.response.id}`}</Typography>
            {state.response.message.length > 0 ?? false ? <Typography>{`Message: ${state.response?.message}`}</Typography> : undefined}
          </Stack>
          ) : undefined
        }
        {state?.status === "error" && state.error ? (
          <Stack>
            <Typography variant="h6">Response: Error</Typography>
            <Typography>{`Message: ${state.error.message}`}</Typography>
          </Stack>
          ) : undefined
        }
      </Stack>
    </Container>
  );
}

export default App;
